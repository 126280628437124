import acpPanCvcCheckTemplate from './templates/acp-pan-cvc-check.html';
// @ngInject
function acpPanCvcCheckDirective() {
  return {
    controller: 'AcpPanCvcCheckComponentCtrl',
    restrict: 'E',
    template: acpPanCvcCheckTemplate,
    scope: {
      authParams: '=',
      form: '=',
      templateLayout: '@'
    },
    link: function(scope, element, attr) {
      Object.defineProperties(scope, {
        isPage: {
          get: function() {
            return !attr.templateLayout || attr.templateLayout === 'page';
          }
        },
        isDialog: {
          get: function() {
            return attr.templateLayout === 'dialog';
          }
        }
      });
    }
  };
}

export default acpPanCvcCheckDirective;
