// @ngInject
function AcpPanCvcCheckComponentCtrl(
  // $log,
  $scope,
  nsUtil,
  acpCoreDispatcher,
  acpCvcAuthenticationModel
) {
  function init() {
    $scope.show = true;
    $scope.authErrors = null;

    $scope.$onValue(acpCoreDispatcher.authenticate.isCvcUpdated, onAuthUpdate);

    signalListeners();

    acpCvcAuthenticationModel.get();

    $scope.$watch(
      function() {
        return $scope.form ? $scope.form.$valid : false;
      },
      function() {
        acpCoreDispatcher.authenticate.cvcPanAuthFormValidity.emit(
          $scope.form.$valid
        );
      }
    );
  }

  function onAuthUpdate() {
    $scope.show = !acpCvcAuthenticationModel.isCvcAuthed();
  }

  function signalListeners() {
    $scope.$onValue(
      acpCoreDispatcher.authenticate.cvcAuth.failed,
      onCvcAuthFailed
    );
  }

  function onCvcAuthFailed(response) {
    $scope.authErrors = {};
    $scope.cvcAuthErrors = [true];

    if (
      response.error === 'auth.invalid' ||
      response.error === 'auth.locked' ||
      response.error === 'auth.expired'
    ) {
      $scope.authErrors[response.error] = [true];
    } else if (
      nsUtil.isDefined(response.error) &&
      nsUtil.isDefined(response._errors)
    ) {
      var errors = {};

      if (nsUtil.isDefined(response.validation_errors)) {
        nsUtil.forEach(response.validation_errors, function(value) {
          var error = value.error;

          if (nsUtil.isDefined(error)) {
            errors[error] = [true];
          }
        });
      }

      $scope.authErrors = errors;
    }
  }

  init();
}

export default AcpPanCvcCheckComponentCtrl;
