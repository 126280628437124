import panCvcCheckFormErrorsContent from './pan-cvc-check-form-errors-content.yml';
import panCvcCheckContent from './pan-cvc-check-content.yml';
import acpPanCvcCheckCtrl from './acp-pan-cvc-check-ctrl';
import acpPanCvcCheckDirective from './acp-pan-cvc-check-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
var component = ng
  .module('acp.component.pan-cvc-check', [acpCore.name])
  .directive('acpPanCvcCheck', acpPanCvcCheckDirective)
  .controller('AcpPanCvcCheckComponentCtrl', acpPanCvcCheckCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/pan-cvc-check', panCvcCheckContent);

      contentSectionCache.put(
        'components/pan-cvc-check.form-errors',
        panCvcCheckFormErrorsContent
      );
    }
  );

export default component;
